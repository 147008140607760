body,html {
  position: relative;
  @include size(100%,100%);
  overflow-x: hidden;
  font-family: $baseFont;
}

body {
	background: #fff;
	z-index: 1;
  	main {
		@include max($maxWidthOuter,none);
		margin: 0 auto;
		// background: yellow;
		.main-wrapper {
			@include max($maxWidthOuter,none);
			margin: 0 auto;
			// background: red;

			min-height: 500px; // temp
		}
		img {
			display: block;
			@include max(100%,none);
		}
		video {
			display: block;
			@include max(100%,none);
		}
		.container-xl {
			&.no-pad {

			}
			@include media-breakpoint-up(md) {
				width: 100%;
				@include max($maxWidthOuter,none);
			}
		}
	}
	&.has-nav {
		section,
		footer {
			display: none;
		}
	}
	
	@include media-breakpoint-up(lg) {
		// background-image: url('../img/bg.png');
		// background-repeat: no-repeat;

		// Colors
		$bg-color: #fff;
		$dot-color: rgba(0,0,0,0.1);

		// Dimensions
		$dot-size: 2px;
		$dot-space: 22px;
		
		background:
			linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
			linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
			$dot-color;
		background-size: $dot-space $dot-space;
		
		section {
			&:first-of-type {
				padding-top: #{$headerSize*1.5};
			}
			.container {
				background: #fff;
			}
		}
		&.color {
			header {
				li {
					a {
						color: #fff;						
					}
					&.active {
						a {
							border: 1px solid #fff;
						}
					}
				}
				.logo {
					filter: invert(100);
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		transition: all 300ms ease-in-out;
		&.has-menu {
			overflow: hidden;
		}
	}

	.btn {
		padding: 10px 32px;
		border-radius: 0;
		border: 2px solid #fff;
		display: inline-block;
		background: transparent;
		&.white {
			color: #fff;
		}
		&.color {
			color: $bgColor1;
			border-color: $bgColor1;
		}
		&.dark {
			color: $bgColor2;
			border-color: $bgColor2;
		}
		&.link {
			border: 0;
			span {
				position: relative;
				display: inline-block;
				@include size(8rem,rem-calc(2));				
				&:after {
					content: '';
					@include absolute(50%,0,auto,auto,10);
					@include size(rem-calc(8),rem-calc(8));
					transform: translateY(-50%) rotate(45deg);
					border-top: 2px solid #fff;
					border-right: 2px solid #fff;					
				}
			}
			&.white {
				span {
					background: #fff;
					&:after {
						border-color: #fff;
					}
				}
			}
		}
	}

	.h1,.h2,.h3,.h4,.h5 {
		display: block;
		margin: 0;
		font-weight: 600;
	}
	.h1 {
		font-size: rem-calc(36);	
		@include media-breakpoint-down(sm) {
			font-size: 8.5vw;
		}	
	}
	.h2 {
		font-size: rem-clac(25);
		font-weight: 700;
	}
	.h3 {
		font-size: rem-clac(20);
	}
	.h4 {
		font-size: rem-clac(18);
	}
	.h5 {
		font-size: rem-calc(24);
		position: relative;
		display: inline-block;
		&:after {
			content: '';			
			@include absolute(auto,auto,0,0,-1);
			@include size(100%,8px);
			background: $bgColor1;
			opacity: 0.75;
		}
	}

	p {
		&.pre-header {
			text-transform: uppercase;
			letter-spacing: 4px;
			font-size: rem-calc(14);
			font-weight: bolder;
		}
		&.main-header {
			font-size: rem-calc(25);
			font-weight: 600;
		}
	}
	.pre-header p {
		text-transform: uppercase;
		letter-spacing: 4px;
		font-size: rem-calc(14);
		font-weight: bolder;
	}

	.card {
		@include size(100%,100%);
		padding: 4.5rem 2.0rem 4.5rem;
		color: #fff;
		border-radius: 0;
		z-index: 10;
		&:before {
			content: '';
			@include absolute(0,auto,auto,2rem,10);
			@include size(0.5rem,2rem);			
		}
		&.color {
			background: $bgColor1;
			&:before {
				background: #fff;
			}
		}
		&.dark {
			background: $bgColor2;
			&:before {
				background: #fff;
			}
		}		
	}

	.video-wrapper {
		&:before {
			content: '';
			@include absolute(0,0,0,0,1);
			@include size(100%,100%);			
		}
	}

	iframe.map {
		@include size(100%,rem-calc(400));
	}

	input[type="text"],
	input[type="email"],
	textarea {
		border-radius: 0 !important;
		font-family: $baseFont;
	}

	section {
		position: relative;

		&.hero {			
			.h1 {
				font-size: rem-calc(55);				
				margin: rem-calc(70) auto;
				font-weight: 300;
				@include media-breakpoint-down(sm) {
					font-size: rem-calc(25);
				}
			}		
			.container {
				background: transparent;
			}	

			.hero-slider {
				@include media-breakpoint-up(md) {
					margin-top: - rem-calc(100);
				}
			}

			&.color {
				background: $bgColor1;
				* {
					color: #fff;
				}
			}

			.head-card {
				@include media-breakpoint-up(lg) {
					margin-top: - rem-calc(200);
				}
			}
			.card {				
				position: relative;
				overflow: hidden;
				&:after {
					content: '';
					@include absolute(0,0,0,0,-1);
					@include size(100%,100%);					
				}
				video {
					@include absolute(0,auto,auto,0,-2);
					@include size(100%,auto);										
				}
			}
		}

		&.about-1 {
			@include media-breakpoint-up(md) {
				margin-top: - rem-calc(200);
			}
		}

		&.realizations {
			margin: rem-calc(50) auto;
			padding: rem-calc(50) 0;	
			background-color: $bgColor2;
			@include media-breakpoint-up(md) {						
				background-image: url('../img/slider-bg.jpg');
				background-size: 80% 100%;
				background-repeat: no-repeat;
				background-attachment: fixed;
				background-position: left 50%;
			}
			.card {
				@include media-breakpoint-up(md) {
					@include absolute(50%,0,auto,auto,10);
					@include size(auto,auto);
					@include min(rem-calc(100),rem-calc(100));
					@include max(rem-calc(400),90%);
					transform: translateY(-50%);				
				}
				@include media-breakpoint-down(sm) {
					@include size(calc(100vw - 2rem),auto);
					margin-left: 1rem;
				}
			}
			.container {
				background: transparent;
			}
		}

		&.portfolio-sliders-section {
			margin: rem-calc(80) auto;
			.portfolio-slider {

			}
			.card {
				@include media-breakpoint-up(md) {
					@include absolute(50%,15px,auto,auto,10);
					@include size(auto,auto);
					@include min(rem-calc(100),rem-calc(100));
					@include max(rem-calc(400),90%);
					transform: translateY(-50%);				
				}
				@include media-breakpoint-down(sm) {
					@include size(calc(100vw - 2rem),auto);
					margin-left: 1rem;
				}
			}
		}

		&.contact-section {
			form {
				.btn {
					font-family: $baseFont;
				}
				small {
					display: block;
					font-size: 10px;
					line-height: 10px;
				}
			}
		}

		&.about-page {
			margin: rem-calc(60) auto rem-calc(10);
			video {
				position: relative;
				&:after {
					content: '';
					@include absolute(0,0,0,0,10);
					@include size(100%,100%);

				}
			}
		}

		&.service-page {
			margin: rem-calc(60) auto rem-calc(10);
			@include media-breakpoint-down(md) {
				.card {
					display: block;
					p,a {
						display: block;
						width: 100%;
						margin: 1rem auto;
					}
					a {
						padding-left: 0.5rem;
						padding-right: 0.5rem;
						white-space: normal;
					}
				}
			}
		}

		&.products-page {
			padding: rem-calc(40) 0;
			border-bottom: 1px solid #d8d8d8;
			.h2 {
				margin: rem-calc(30) auto;
			}			
			@include media-breakpoint-down(sm) {
				img {
					transform: rotate(-90deg);
					transform-origin: 50% 50%;
					margin: -30% auto;
				}
				.btn {
					margin: 20px auto;
				}
			}			
		}

	}





}