header {
    @include absolute(0,auto,auto,50%,10);
    transform: translateX(-50%);
    @include size(100%,$headerSize);
    margin: 0 auto;
    @include media-breakpoint-up(lg) {
        top: 2rem;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        @include media-breakpoint-up(lg) {
            align-items: center;
        }
        li {
            display: inline-block;
            position: relative;
            &:first-of-type {
                @include media-breakpoint-up(lg) {
                    display: none;
                }

            }
            a {
                text-transform: uppercase;
                color: #000;
                padding: 0.875rem 1rem;
                font-weight: 500;
                font-size: rem-calc(14);
                &:hover {
                    color: inherit;
                    text-decoration: none;
                }
            }
            &.active {
                @include media-breakpoint-up(lg) {                    
                    a {
                        border: 1px solid #000;                    
                    }
                }
                // @include media-breakpoint-down(md) {                    
                //     background: $bgColor1;
                //     a {
                //         border: 0;
                //         color: #fff;
                //     }
                // }
            }
            &.lang-switch {     
                padding-left: 5px;
                a {
                    border: 1px solid #000;
                    // padding: 0.875rem 0.4rem 0.875rem 0.2rem;
                    padding: 0.875rem 0.3rem 0.875rem 0.3rem;
                    // margin: 0 -3px;
                    &:nth-of-type(1) {
                        // border-right: 0;
                    }
                    &:nth-of-type(2) {
                        // border-right: 0;
                    }
                    &:nth-of-type(3) {
                        // padding-right: 0.2rem;
                    }
                    &.active {
                        background: #000;
                        color: #fff;
                    }
                }
                @include media-breakpoint-down(md) {
                    padding: 30px 10px 10px;
                    text-align: center;
                    border-bottom: 0;
                    a {
                        width: 33%;
                        margin: 0 -3px;
                        display: inline-block;
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }
            }
            &[data-type="has-children"] {
                ul {                    
                    @include media-breakpoint-up(lg) {
                        margin-top: -15px;                    
                        position: absolute;
                        left: 50%;
                        @include size(auto,auto);
                        transform: translateX(-50%);
                        transition: all 200ms ease-in-out;
                        transition-delay: 100ms;
                        opacity: 0;
                        visibility: hidden;
                        display: none;                    
                        padding: 1.5rem 1rem 1rem;
                        li {
                            display: block;
                            text-align: left;
                            padding: 3px 0;
                            margin-bottom: 5px;
                            border-bottom: 1px solid #dadada;
                            a {
                                padding: 0;
                                color: #fff;
                                white-space: nowrap;
                            }
                        }
                        &:before {
                            content: '';
                            @include absolute(auto,auto,0,0,0);
                            @include size(100%,95%);
                            background: $bgColor2;
                        }
                        &:after {
                            content: '';
                            @include absolute(3px,auto,auto,50%,0);
                            @include size(15px,15px);
                            transform: translateX(-50%) rotate(45deg);
                            background: $bgColor2;
                        }
                    }                    
                }
                &:hover {
                    ul {
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        margin-top: 0;
                    }
                }
                @include media-breakpoint-down(md) {
                    padding: 0;
                    background: transparent !important;
                    color: #000;
                    a {
                        background: transparent;
                        color: #000;
                    }
                }
            }
        }
    }
    .logo {
        @include media-breakpoint-down(md) {
            @include size(55vw,auto);
            @include max(250px,none);
            transform: translateY(25%);
        }
    }
    .hamburger {
        @include absolute(0,1rem,auto,auto,10);
        transform: translateY(50%);
        span {
            display: none;
            font-size: 1.5rem;
            &.show {
                display: block;
            }
        }
    }
    @include media-breakpoint-down(md) {
        @include relative();
        transform: translateX(0);
        z-index: 100;
        ul.nav {
            @include fixed($headerSize,0,auto,auto,100);
            @include size(100vw,auto);
            transition: all 300ms ease-in-out;
            background: #fff;            
            transform: translateX(100vw);
            li {
                display: block;
                width: 100%;
                // padding: 10px;
                padding: 0px;
                border-bottom: 1px solid #0f0f0f; 
                a {
                    width: 100%;
                    padding: 15px;
                    display: block;
                }            
                ul {
                    li {                        
                        &:last-of-type {
                            border-bottom: 0;
                        }
                    }
                } 
                &.lang-switch {
                    a {
                        display: inline-block;
                        width: 33%;
                    }
                }  
            }
            &.active {
                transform: translateX(0);
            }
        }
    }    
}