@media #{$sm} { .hide-sm {display: none !important; }}
@media #{$md} { .hide-md {display: none !important; }}
@media #{$lg} { .hide-lg {display: none !important; }}
@media #{$hg} { .hide-hg {display: none !important; }}

body.noScroll {
	overflow:  hidden;
}

.alignCenter {
  text-align: center;
}

.valignCenter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.off {
    pointer-events: none;    
}

@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}