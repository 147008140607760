//$debug: true;
$debug: false;

$baseFont: 'Montserrat', sans-serif;

$sm: "only screen and (min-width: 0) and (max-width: 641px)";
$md: "only screen and (min-width: 641px) and (max-width: 1100px)";
$mdlg: "only screen and (min-width: 1101px) and (max-width: 1600px)";
$lg: "only screen and (min-width: 1101px)";
$hg: "only screen and (min-width: 1921px)";

$maxWidthInner: 1320px;
$maxWidthOuter: 1920px;

$headerSize: 85px;
$globalPadding: 30px;

$bgColor1: #0979d2;
$bgColor2: #292929;
$txtColor1: #808080;
$txtColor2: #c5c5c5;

@media #{$sm} {
    .hide-sm {
        display: none !important;
    }
}
@media #{$md} {
    .hide-md {
        display: none !important;
    }
}
@media #{$lg} {
    .hide-lg {
        display: none !important;
    }
}