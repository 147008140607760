footer {
    .logo {
        width: 80%;
    }
}

.cookiesNotification {
    @include fixed(auto,auto,0,0,100);
    @include size(100%,auto);
    padding: rem-calc(15);
    background: #f8f8f8;
    color: #000;
    p {
        display: inline-block;
        float: left;
        margin: 0;
        padding: 10px;
    }
    .closeNotification {
        display: inline-block;
        border: 1px solid #000;
        padding: 10px;
        float: right;
        cursor: pointer;
        background: #000;
        color: #fff;        
    }
}